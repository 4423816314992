@import "./src/assets/scss/main.scss";



























































































































































































































































































































































































































































.available-time {
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
        cursor: pointer !important;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 14px 0;
        font-family: Montserrat;
        font-size: 16px;
        border: 2px solid $orange;
        cursor: pointer !important;
        color: $orange;
        border-radius: 9px;
        margin-right: 10px;
        text-align: center;
        min-width: 160px;
    }

    label:hover {
        background-color: $orange;
        border-color: $orange;
        color: #fff;
    }

    input[type="radio"]:focus+label {
        // background-color: $orange;
        // border-color: $orange;
        // color: #fff;
    }

    input[type="radio"]:checked+label {
        // background-color: $orange;
        // border-color: $orange;
        // color: #fff;
    }

    &__item {
        display: flex;
        overflow-x: scroll;
        flex-wrap: wrap;

        div {
            margin-bottom: 5px;
        }

        @include media-breakpoint-down(md) {
            flex-wrap: nowrap;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.available-time-detail {
    display: flex;
    overflow-x: scroll;
    flex-wrap: wrap;

    div {
        margin-bottom: 5px;
    }

    @include media-breakpoint-down(md) {
        flex-wrap: nowrap;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 10px 0;
        font-family: Montserrat;
        font-size: 16px;
        border: 2px solid #3F2A2C;
        color: #3F2A2C;
        cursor: pointer !important;
        border-radius: 10px;
        margin-right: 10px;
        text-align: center;
        min-width: 144px;
    }

    label:hover {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }

    input[type="radio"]:focus+label {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }

    input[type="radio"]:checked+label {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }
}

.way {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
        flex-wrap: nowrap;
    }

    :checked+span {
        //cursor: pointer;
        border: 1px solid $orange;
        //   padding-left: 42px;
    }

    /* Optional other stuff just to make it look better */
    span {
        width: auto;
        display: flex;
        align-items: center;
        padding: 5px;
        padding-right: 51px;
        height: 56px;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        border-radius: 9px;
        font-family: Montserrat;
        padding-right: 48px;
        padding-left: 50px;
        font-size: 16px;
        cursor: pointer;
        white-space: nowrap;
    }

    label {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 12px;
        margin-bottom: 10px;
    }

    label:hover {}

    input {

        position: absolute;
        left: 12px;
        top: 19px;
    }

    input[type='radio']:after {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #ddd;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
        border: 6px solid #ddd;

    }

    input[type='radio']:checked:after {
        border: 6px solid $orange;
        width: 21px;
        height: 21px;
        //  height: 9px;
        border-radius: 50%;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #fff;
        content: '';
        display: inline-block;
        visibility: visible;
        //  border: 2px solid white;
    }
}

.cart {
    padding-top: 46px;

    @include media-breakpoint-down(md) {
        padding-top: 14px;
    }

    &__header {
        font-size: 45px;
        line-height: 100.74%;

        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }

    &__order {}

    &__order-date {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 142.69%;

    }

    &__order-items {}

    &__img {}

    &__name {}

    &__counter {}

    &__price {}
}

.order-info {
    padding-top: 70px;
    padding-bottom: 51px;
    background: #F9F9F9;

    @include media-breakpoint-down(md) {
        padding-top: 32px;
        text-align: left;
    }

    &__header-desc {}

    &__sec {}

    &__header-desc {
        font-family: Montserrat;
        //   padding-bottom: 30px;
        font-weight: 500;
        font-size: 18px;
        line-height: 150.4%;

        color: #999999;

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 150.4%;
            padding-bottom: 10px;
        }
    }
}

.cart-header-sec {
    font-size: 30px;
    line-height: 100.74%;

    @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }
}

.g-3 {}

.form-label {}

.form-control {}

.is-valid {}

.is-invalid {}

.select-time {
    &__change-time {}
}

.available-time {
    &__item {}
}

.available-time-detail {}

.order-readiness {
    margin-top: 37px;

    @include media-breakpoint-down(md) {
        margin-top: 30px;

    }

    &__status {
        background: #EFEFEF;
        display: flex;
        align-items: center;
        padding-left: 30px;
        width: 318px;
        height: 74px;
        font-size: 20px;
        border-radius: 9px;

        @include media-breakpoint-down(md) {
            margin-left: auto;
            margin-right: auto;
        }

        b {
            color: $orange;
            padding-left: 10px;
        }
    }

    &__desc {
        font-family: Montserrat;
        padding-left: 20px;
        padding-top: 10px;
        font-size: 14px;
        line-height: 150.4%;
        max-width: 650px;

        @include media-breakpoint-down(md) {
            text-align: center;
            margin-top: 5px;
        }
    }
}

.way {
    @include media-breakpoint-down(md) {
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.cart-phone-number {
    input {
        border: none;
        border-radius: 73px;
        height: 57px;
        font-family: Montserrat;

        color: #000000;
        padding-left: 29px;

        font-weight: 600;
        font-size: 16px;

        &::placeholder {
            font-weight: 400;
        }
    }

    p {
        margin-top: 10px;
        font-family: Montserrat;

        font-size: 12px;
        line-height: 142.69%;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        span {
            color: $orange;
        }
    }
}

.input-group {}

.pay-int-account {

    background: #F5F5F5;
    border-radius: 9px;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
    align-items: center;
    max-width: 645px;

    @include media-breakpoint-down(md) {
        padding-top: 19px;
        padding-left: 5px;
        padding-right: 5px;
    }

    &__desc {
        @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 16px;
        }
    }

    &__btns {
        display: flex;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        input {
            height: 59px;
            width: 127px;
            background: #FFFFFF;
            border-radius: 73px;
            text-align: center;
            font-size: 16px;
            font-family: Montserrat;
            font-weight: bold;
            margin-right: 15px;
            border-color: #fff;

        }
    }
}

.total-sum {
    margin-top: 75px;
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
        text-align: center;
        margin-top: 35px;
        margin-bottom: 60px;
    }

    span {
        font-weight: bold;
        font-size: 30px;
        line-height: 100.74%;

        @include media-breakpoint-down(md) {
            font-size: 24px;
            margin-bottom: 17px;
        }
    }

    span.orange {
        color: $orange;
    }

    strike {
        font-size: 30px;
        line-height: 100.74%;
        color: #AFAFAF;
    }

    p {
        font-family: Montserrat;
        margin-top: 15px;
        margin-bottom: 34px;
        font-size: 14px;
        line-height: 150.4%;
        /* identical to box height, or 21px */

        color: #2B2B2B;

        @include media-breakpoint-down(md) {
            margin-top: 25px;
        }

        b {
            font-weight: 600;
        }
    }

    button {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

.cart-order {
    font-family: Montserrat;
    margin-bottom: 53px;
    font-weight: 600;
    font-size: 20px;
    line-height: 142.69%;
    margin-bottom: 32px;

    &__date {
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }

    &__items {}
}

.order-info-days {
    .form-control {
        font-family: Montserrat;
        font-weight: 600;
        padding-left: 0;
        font-size: 16px;
        line-height: 20px;
        padding-top: 0;
        padding-bottom: 10px;
    }

    .form-control {
        max-width: 390px;
    }

    .form-label {
        display: block;
        margin-bottom: -10px;
        font-size: 20px;

        @include media-breakpoint-down(md) {
            margin-bottom: -14px;
        }
    }

    .form-control.is-valid,
    .form-control.is-valid:focus {
        border: none;
        border-bottom: 1px solid #000000;
        box-shadow: none;
        border-radius: 0;

        background: url(../assets/true.svg) 100% 10% no-repeat #F9F9F9;
        padding-top: 20px;
    }

    .form-control.is-invalid,
    .form-control.is-invalid:focus {
        border: none;
        border-bottom: 1px solid #000000;
        box-shadow: none;
        border-radius: 0;

        background: url(../assets/false.svg) 100% 10% no-repeat #F9F9F9;
        padding-top: 20px;
    }
}

.your-sum {
    font-family: Montserrat;
    font-size: 18px;
    line-height: 100.74%;
    margin-top: 22px;
    margin-bottom: 22px;

    @include media-breakpoint-down(md) {
        text-align: center;
        margin-top: 10px;
    }

    span {
        color: $orange;
        font-weight: bold;
    }
}

.order-info-days__wrp {
    cursor: pointer;

    input,
    label {
        cursor: pointer;
    }
}

.order-info__sec {}

.active {
    background: #fd7e14 !important;
    color: #fff !important;
    cursor: pointer !important;
}

.activeInput {
    border-bottom: 1px solid #fd7e14 !important;
}

.select-time {
    font-size: 24px;
    line-height: 109.5%;
    position: relative;

    b {
        @include media-breakpoint-down(md) {
            display: block;
            margin-top: 7px;
        }
    }

    span {
        display: block;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        line-height: 109.5%;
        width: fit-content;
        color: $orange;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            display: block;
            position: absolute;
            top: 35px;
            right: 20px;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 109.5%;
    }
}

.total-kkal {
    display: flex;
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 100.74%;
    /* or 24px */
    margin-top: 15px;

    color: #000000;

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
    }
}

.total-kkal__desc {

    /* or 24px */

    color: #000000;

    @include media-breakpoint-down(md) {
        margin-bottom: 15px;
    }
}

.total-kkal__sum {
    color: $orange;
}
