@import "./src/assets/scss/main.scss";































































































































.cart-phone-number {
    input {
        border: none;
        border-radius: 73px;
        height: 57px;
        font-family: Montserrat;

        color: #000000;
        padding-left: 29px;

        font-weight: 600;
        font-size: 16px;

        &::placeholder {
            font-weight: 400;
        }
    }

    p {
        margin-top: 10px;
        font-family: Montserrat;

        font-size: 12px;
        line-height: 142.69%;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        span {
            color: $orange;
        }
    }
}
.fixw{
    width: 188px;
}
